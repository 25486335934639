@import "@/styles/globals.scss";
@import '../../styles/defaults/css-properties';

// GRPM variables.
$green: #8cc340;
$blue: #04b1ff;
$orange: #f47e3c;
$grpm-gray: #bfc1ca;

$warning-color: $orange;
$link-hover-color: $orange;

$very-dark-gray: #2f3033;
$medium-gray: #555;
$light-gray: #e2e5eb;
$very-light-gray: #f6f6f6;

$selectable-height: 48px;
$steppers-3-border-width: 2px;
$steppers-3-box-size: 48px;

$members-menu-icon-color: $blue;
$info-color: $blue;
$info-background-color: lighten($blue, 45%);

$steppers-3-border: 2px solid $light-gray;
$steppers-3-button-background-color: white;
$steppers-3-button-background-hover-color: $very-light-gray;
$steppers-3-border-hover-color: $medium-gray;
$steppers-3-icon-color: $medium-gray;
$steppers-3-disabled-icon-color: $light-gray;

$radio-button-border-color: $light-gray;
$radio-button-background-color: white;
$radio-button-checked-background-color: white;
$radio-button-checked-border-color: $blue;
$radio-button-selected-color: $blue;
$radio-button-border-width: 2px;

$cart-price-background-color: $very-light-gray;

// Defaults
@import '../../styles/defaults/index';
@import '../../styles/helpers/index';

$datepicker-icon-color: $dark-gray;
$datepicker-icon-color-hover: $dark-gray;
$datepicker-icon-color-active: $green;

$select-arrow-color: $dark-gray;

// Placeholders.
%p {
  margin-top: 20px;
  margin-bottom: 20px;
}

%h1 {
  color: $dark-gray;
  line-height: 48px;

  @include mobileExperience {
    font-size: 30px;
  }
}

%h2 {
  color: $dark-gray;
  font-size: 24px;
  line-height: 32px;
}

%button {
  padding: 13px 16px 15px;
  border-width: 2px;
  border-radius: 4px;
  font-weight: bold;
}

%primary-button {
  padding-left: 28px;
  padding-right: 28px;
}

%primary-button-hover {
  color: white;
  background-color: $medium-gray;
}

%secondary-button {
  background-color: white;
  border: 2px solid $light-gray;
  color: $dark-gray;
  min-height: $selectable-height;
}

%secondary-button-hover {
  background-color: white;
  border-color: $grpm-gray;
  color: $dark-gray;
}

%secondary-button-active {
  background-color: white;
  border-color: $green;
  color: $dark-gray;
}

%back-button {
  font-weight: bold;

  &:hover,
  &:focus {
    color: $orange;
  }
}

%label {
  font-family: var(--primary-font);
  font-weight: bold;
  color: $dark-gray;
}

%input {
  border-color: $light-gray;
  border-radius: 4px;

  // TODO Keep vertical rhythm in shared styles.
  height: 46px;
}

%input-hover {
  border-color: $grpm-gray;
}

// Mixins
@include unwrapIdentityFields(city, state, 1 * 0.5);
@include unwrapIdentityFields(zip-code, country, 0.333333);

// Base elements
@import 'page';
@import 'elements';
@import 'links-and-buttons';
@import 'form-inputs';
@import 'modals';
@import 'messages';
@import 'badges';

// Components
@import '_header';
@import '_footer';
@import 'filters';
@import 'actions';
@import 'cart';
@import 'selection';
@import 'steppers-3';
@import 'calendar';
@import 'identity-form';
@import 'members-menu';
@import 'legend';

// Routes
@import 'checkout';
@import 'completed';
@import 'event-list';
@import 'buy-membership';
@import 'gift-of-membership';
@import 'forgot-membership';
@import 'event';
@import 'resend-tickets';
@import 'manage-order';
@import 'home';
@import 'my-account';

// Theme
@import 'sass/styles';
@import 'sass/header';
@import 'sass/footer';
