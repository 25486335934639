.site-footer {
  @include responsive(margin-top, 32px, 96px);
  background: darken($very-dark-gray, 5%);
  color: white;
  text-align: center;

  p {
    color: white;
    margin-bottom: 0;
    &:first-child {
      margin-top: 0;
    }
  }

  a.become-a-member {
    display: block;
    background: $very-dark-gray;
    padding: 56px 16px;
    h2 {
      margin-top: 0;
      font-family: var(--secondary-font);
      color: white;
      :hover & {
        color: white;
      }
    }
    p {
      font-size: 20px;
      line-height: 32px;
      color: $blue;
    }
  }

  .small-print {
    @extend %content-container;
    padding: 16px;

    p {
      font-size: 14px;
      line-height: 20px;
    }

    @include large {
      padding: 0;
      display: flex;
      > * {
        flex-basis: 50%;
        padding: 16px;
        margin: 0;
        &:first-child {
          text-align: left;
        }
        &:last-child {
          text-align: right;
        }
      }

      a[href^='tel:']:before {
        content: '• ';
        margin: 0 8px;
        color: $medium-gray;
      }
    }
  }
}
