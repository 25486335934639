.site-header {
  box-shadow: 0 0 5px 0 $medium-gray;

  .logo {
    @include responsiveProperty(max-width, 158px, 158px, 292px);
    @include responsiveProperty(margin, 20px 0, 36x 0, 36px 0);
  }

  // GRPM account link font styles.
  .account-links > a {
    font-weight: bold;
    @include responsiveProperty(font-size, 14px, 18px, 18px);
  }
}
